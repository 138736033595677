import React, { useEffect, useMemo, useState } from "react"

import { useApp } from "@hooks/useApp"
import { useCore } from "@hooks/useCore"
import { useLanguage } from "@hooks/useLanguage"
// import { useSearch } from "@hooks/useSearch"

export const withSearchFilter = Component => ({
  name = `SearchFilter`,
  collection,
  filtersActive,
  setFiltersActive,
  setMeasuring,
  type = "collection",
}) => {
  const [active, setActive] = useState(null)
  const {
    config: {
      settings: { keys },
    },
  } = useApp()
  const {
    helpers: { storage },
  } = useCore()

  const locales = {
    ...useLanguage(`accessibility`),
    ...useLanguage(`products`),
    ...useLanguage(`collection`),
  }

  const handleCloseFilter = () => {
    setFiltersActive(null)
    setActive(null)
  }

  Component.displayName = name
  return (
    <Component
      active={active}
      filtersActive={filtersActive}
      locales={locales}
      handleCloseFilter={handleCloseFilter}
      setActive={setActive}
      setMeasuring={setMeasuring}
    />
  )
}
