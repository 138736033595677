import React from "react"

import { withSearchSort } from "./withSearchSort"
import { Container } from "../../Styled/Container"
import { Checkbox } from "../../Styled/Checkbox/Checkbox"
import {
  Dropdown,
  Content,
  Row,
  Values,
  Value,
  Panel,
  Controls,
  StyledTextButton,
  PreviousIcon,
  StyledDropdownLabel,
} from "../Filter/SearchFilterStyles"
import { useSortBy } from "@usereactify/search"

export const SearchSort = withSearchSort(({ active, locales, setActive }) => {
  const { sortOption, sortOptions, setSortOption } = useSortBy()
  return (
    <>
      <Dropdown active={active === locales.filterSortBy} onMouseEnter={() => setActive(locales.filterSortBy)} onMouseLeave={() => setActive(null)}>
        <Content>
          <Container width={`lg`}>
            <Row>
              <Values>
                {sortOptions.map(opt => (
                  <Value key={opt.id}>
                    <Checkbox
                      key={opt.id}
                      checked={sortOption?.handle === opt.handle}
                      externalState
                      large
                      name={opt.name}
                      onChange={() => setSortOption?.(opt.handle)}
                      isFilter={true}
                    >
                      {opt.name}
                    </Checkbox>
                  </Value>
                ))}
              </Values>
            </Row>
          </Container>
        </Content>
      </Dropdown>

      <Panel active={active === locales.filterSortBy}>
        <Container>
          <Controls>
            <StyledTextButton onClick={() => setActive(null)}>
              <PreviousIcon />
              <span>{locales.back}</span>
            </StyledTextButton>
          </Controls>

          <StyledDropdownLabel>{locales.filterSortBy}</StyledDropdownLabel>

          <Row>
            <Values>
              {sortOptions.map(opt => (
                <Value key={opt.id}>
                  <Checkbox
                    key={opt.id}
                    centered
                    checked={sortOption?.handle === opt.handle}
                    externalState
                    large
                    name={opt.name}
                    handleChange={() => setSortOption?.(opt.handle)}
                  >
                    {opt.name}
                  </Checkbox>
                </Value>
              ))}
            </Values>
          </Row>
        </Container>
      </Panel>
    </>
  )
})
