import styled from "@emotion/styled"
import tw from "twin.macro"

import ArrowRight from "static/icons/theme/arrow-right.svg"
import Previous from "static/icons/theme/previous.svg"
import Close from "static/icons/theme/close.svg"

import { Container } from "../../Styled/Container"
import { Label } from "../../Styled/Text"
import { TextButton } from "../../Styled/Button"

export const DesktopFilter = styled.div`
  ${tw`hidden md:block py-1-2 border-t -mt-0-1 transition-all duration-200 duration-100 cursor-pointer`}
  ${props => (props.active ? tw`text-dark border-dark` : tw`text-grey-darker hover:text-grey-darkest border-transparent hover:border-grey-darkest`)}
`
export const MobileFilter = styled.button`
  ${tw`w-full flex items-center justify-between md:hidden py-1-6 px-0-8 text-dark border-t border-grey-DEFAULT cursor-pointer focus:outline-none transform transition-all duration-200`}
  ${props => (props.active ? tw`invisible -translate-x-full pointer-events-none` : tw`visible translate-x-0`)}
`
export const StyledLabel = styled(Label)`
  ${tw`text-xms pointer-events-none`}
  ${props => (props.palette ? tw`relative flex items-center justify-center px-1-2` : tw`md:px-1-2`)}
`
export const Sup = tw.sup`text-xxxs ml-0-2`
export const ArrowRightIcon = tw(ArrowRight)`block w-1 h-1`
export const Dropdown = styled.div`
  ${tw`hidden md:block absolute top-9/10 w-full z-50 transition-all duration-200`} ${props =>
    props.active ? tw`opacity-100 visible` : tw`opacity-0 invisible duration-100 pointer-events-none`}
`
export const Panel = styled.div`
  ${tw`block md:hidden overflow-x-hidden overflow-y-auto fixed bg-light inset-x-0 top-8 bottom-10 w-full z-50 transform transition-all duration-200`}
  ${props => (props.active ? tw`visible translate-x-0` : tw`invisible translate-x-full pointer-events-none`)}
`
export const Content = styled.div`
  ${tw`bg-light pb-3-2 pt-2-4 mt-0-1`}
`
export const Row = tw.div`flex items-center justify-between`
export const Values = tw.div`w-full md:w-3/4 flex flex-wrap items-start justify-start`
export const Value = styled.div`
  ${tw`w-1/2 md:w-1/5 pb-1-6`}
  ${({ sorting }) => sorting && tw`md:w-auto md:mr-3`}
`
export const Info = tw.div`w-full md:w-1/2 flex items-center justify-start pt-3-8`
export const Controls = tw.div`w-full md:w-1/2 flex items-center justify-start md:justify-end border-b border-grey-DEFAULT md:border-b-0 pb-0-6 md:pb-0 pt-3-8`
export const PreviousIcon = tw(Previous)`block mr-0-4 w-2 h-2`
export const CloseIcon = styled(Close)`
  ${tw`block`}
  ${props => (props.large ? tw`w-2 h-2` : tw`ml-1 w-1-6 h-1-6`)}
`
export const StyledDropdownLabel = tw(Label)`py-1-6 text-xms`
export const StyledTextButton = tw(
  TextButton
)`text-sm md:text-xs text-dark md:text-grey-darker flex items-center justify-start md:justify-end hover:no-underline hover:text-dark transition-all duration-200`
export const StyledLink = tw.a`inline-block ml-2-8 text-xs underline focus:outline-none hover:no-underline`

export const Toggle = tw.div`mx-1-2 pb-0-1`

export const Filter = styled.div`
  ${tw`py-1-2 border-t -mt-0-1 transition-all duration-200 duration-100 cursor-pointer`} ${props =>
    props.active ? tw`text-dark border-dark` : tw`text-grey-darker hover:text-grey-darkest border-transparent hover:border-grey-darkest`}
`

export const Wrapper = styled.div`
  ${tw`fixed inset-0 md:bottom-auto md:inset-x-auto md:sticky bg-light z-50 md:z-20 transition-all duration-200 md:transition-none`}
  ${props => (props.active ? tw`opacity-100 visible` : tw`opacity-0 invisible md:opacity-100 md:visible`)}
`
export const FilterPanel = styled.div`
  ${tw`w-full relative md:z-30 bg-light h-full md:h-auto transform transition-all duration-200 md:transition-none`}
  ${props => (props.active ? tw`translate-x-0` : tw`translate-x-full-vw md:translate-x-0`)}
`
export const StyledContainer = tw(Container)`h-full flex flex-col md:block md:h-auto max-w-lg`
export const FiltersWrapper = tw.div`flex-1 overflow-x-hidden overflow-y-auto md:overflow-hidden block md:flex items-center justify-between md:border-t border-grey-DEFAULT md:min-h-3-6`
export const Items = styled.div`
  ${tw`items-center w-full`}
  ${props =>
    props.align === `start`
      ? tw`md:w-1/4 hidden md:flex justify-start`
      : props.align === `end`
      ? tw`md:w-1/4 hidden md:flex justify-end`
      : props.align === `stretch`
      ? tw`flex flex-col flex-1 md:flex-row items-stretch md:items-center justify-start md:justify-center border-b border-grey-DEFAULT md:border-b-0 pt-4 md:pt-0`
      : tw`flex flex-col flex-1 md:flex-row items-start md:items-center justify-start md:justify-center border-b border-grey-DEFAULT md:border-b-0 pt-4 md:pt-0
  `}
`
export const ItemsFilter = styled.div`
  ${tw`items-center`}
  ${props =>
    props.align === `start`
      ? tw`hidden md:flex justify-start`
      : props.align === `end`
      ? tw`hidden md:flex justify-end`
      : tw`flex flex-col flex-1 md:flex-row items-start md:items-center justify-start md:justify-center border-b border-grey-DEFAULT md:border-b-0 pt-4 md:pt-0
`}
`
export const MobileHeader = tw.div`flex md:hidden items-center justify-between py-1-2`
export const MobileHeaderColumn = styled.div`
  ${tw`w-1/2`}
  ${props =>
    props.align === `right`
      ? tw`text-right`
      : tw`text-left
  `}
`
export const MobileFooter = tw.div`block md:hidden py-1-2`
export const CloseButton = tw.button`focus:outline-none`
export const ResetButton = styled(TextButton)`
  ${tw`mx-auto inline-block mb-1-6 text-xs text-grey-darker hover:no-underline flex items-center justify-start transition-all duration-200`}
  ${props => (props.hidden ? tw`opacity-0 invisible` : tw`opacity-100 visible`)}
`
export const Overlay = styled.div`
  ${tw`hidden md:block fixed inset-0 bg-dark z-20 transition-all duration-200`}
  ${props =>
    props.active
      ? tw`opacity-70 visible`
      : tw`opacity-0 invisible duration-100 pointer-events-none
  `}
`
