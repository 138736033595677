import { useShopify } from "./useShopify"

export const useSearch = (location = null) => {
  const { formatMoney } = useShopify()
  const sortFilterList = (filters: any) => {
    const sortOrder = ["price", "price_new", "fit", "size", "colour", "fabric", "style"]

    return filters.sort((a, b) => {
      const aName = a.name.toLowerCase()
      const bName = b.name.toLowerCase()

      const aIndex = sortOrder.indexOf(aName)
      const bIndex = sortOrder.indexOf(bName)

      if (aIndex !== -1 && bIndex !== -1) {
        return aIndex - bIndex
      }

      if (aIndex !== -1) {
        return -1
      }

      if (bIndex !== -1) {
        return 1
      }

      return 0
    })
  }

  const sortFilters = ({ key: keyA }, { key: keyB }) => {
    const stringOrder = ["Small", "Medium", "Large", "Extra Large"]

    if (!isNaN(keyA) && !isNaN(keyB)) return Number(keyA) - Number(keyB)
    if (isNaN(keyA) && !isNaN(keyB)) return 1
    if (!isNaN(keyA) && isNaN(keyB)) return -1

    const indexA = stringOrder.indexOf(keyA)
    const indexB = stringOrder.indexOf(keyB)

    if (indexA < 0 && indexB < 0) {
      if (keyA > keyB) return 1
      if (keyA < keyB) return -1
      return 0
    }
    if (indexA < 0) return 1
    if (indexB < 0) return -1

    return indexA - indexB
  }

  const mutateFiltersList = (items = [], name) => {
    if (name !== "Size") {
      const newlist = renamePriceFilter(items, name)
      return newlist
    }

    const keywords = ["small", "medium", "large", "extra large"]
    return items?.filter(({ key }) => (key && !isNaN(key) && key !== "") || keywords.includes(key?.toLowerCase())).sort(sortFilters)
  }

  const renamePriceFilter = (options: any, name: string) => {
    if (!name.toLowerCase()?.includes("price")) return options

    const optionNew = options.map(opt => {
      let name
      if (opt.key.includes("Under ")) {
        name = `Under ${formatMoney(opt.key.replace("Under ", ""), "")}`
      } else if (opt.key.includes(" +")) {
        name = `${formatMoney(opt.key.replace(" +", ""), "")} +`
      } else {
        const [min, max] = opt.key.split(" - ")
        name = `${formatMoney(min, "")} - ${formatMoney(max, "")}`
      }
      return { ...opt, name }
    })

    return optionNew
  }

  return {
    sortFilterList,
    mutateFiltersList,
  }
}
