import React from "react"

import { Toggle } from "./SearchFilterStyles"
import { Checkbox } from "../../Styled/Checkbox/Checkbox"

export const SearchFilterGrid = ({ filterListProps }) =>
  filterListProps.options.map(opt => (
    <>
      <Toggle key={opt.key}>
        <Checkbox
          key={opt.key}
          centered
          checked={opt.checked}
          externalState
          onChange={() => filterListProps.handleChange(opt.key)}
          rounded
          isFilter={true}
        >
          {opt.key?.replace(/-/g, ` `)}
        </Checkbox>
      </Toggle>
    </>
  ))
