import React from "react"

import { Small } from "../../Styled/Text"
import { Stats } from "@usereactify/search"

export const SearchFilterCount = () => (
  <Stats
    render={({ resultStats }) => {
      return resultStats.numberOfResults ? (
        <Small>{`${resultStats.numberOfResults} Results`}</Small>
      ) : (
        <Small dangerouslySetInnerHTML={{ __html: `&nbsp;` }} />
      )
    }}
  />
)
