import React from "react"

import { DesktopFilter, MobileFilter, StyledLabel, Sup, ArrowRightIcon } from "./SearchFilterStyles"

export const SearchFilterList = ({ active, setActive, filter, totalSelected }) => (
  <>
    {(filter.displayView === "list" || filter.displayView === "range") && (
      <>
        <DesktopFilter active={active === filter.name} onMouseEnter={() => setActive(filter.name)} onMouseLeave={() => setActive(null)}>
          <StyledLabel>
            {filter.name == "Price_new" ? "price" : filter.name}
            {!!totalSelected && <Sup>{totalSelected}</Sup>}
          </StyledLabel>
        </DesktopFilter>
        <MobileFilter active={active ? `true` : undefined} onClick={() => setActive(filter.name)}>
          <StyledLabel>
            {filter.name == "Price_new" ? "price" : filter.name}
            {!!totalSelected && <Sup>{totalSelected}</Sup>}
          </StyledLabel>
          <ArrowRightIcon active={active === filter.name} />
        </MobileFilter>
      </>
    )}
  </>
)
