import React from "react"

import { useLanguage } from "@hooks/useLanguage"

export const withSearchSort = Component => ({ name = `SearchSort`, active, setActive }) => {
  const locales = {
    ...useLanguage(`accessibility`),
    ...useLanguage(`collection`),
  }

  Component.displayName = name
  return <Component active={active} locales={locales} setActive={setActive} />
}
