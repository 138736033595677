import React, { useCallback, useState } from "react"

import { Filter, StyledLabel } from "./SearchFilterStyles"

export const SearchFilterPalette = ({ locales, filterListProps }) => {
  const { options, handleChange } = filterListProps
  const handleOnChange = useCallback(val => {
    if (val) {
      handleChange(val)
    } else {
      options
        .filter(opt => opt.checked)
        .forEach((opt, index) => {
          setTimeout(() => {
            handleChange(opt.key)
          }, index * 500)
        })
    }
  }, [])

  if (!options) return null
  return (
    <>
      <Filter
        active={!options.some(({ checked }) => checked)}
        onClick={() => {
          handleOnChange(null)
        }}
      >
        <StyledLabel palette>{locales.all}</StyledLabel>
      </Filter>

      {options?.length > 1
        ? options.map(opt => (
            <Filter
              key={opt.key}
              active={opt.checked}
              onClick={() => {
                handleOnChange(opt.key)
              }}
            >
              <StyledLabel palette>{opt.key}</StyledLabel>
            </Filter>
          ))
        : null}
    </>
  )
}
