import React from "react"

import { withSearchSortToggle } from "./withSearchSortToggle"

import { DesktopFilter, StyledLabel, MobileFilter, ArrowRightIcon } from "../Filter/SearchFilterStyles"

export const SearchSortToggle = withSearchSortToggle(({ active, locales, setActive }) => (
  <>
    <DesktopFilter active={active === locales.filterSortBy} onMouseEnter={() => setActive(locales.filterSortBy)} onMouseLeave={() => setActive(null)}>
      <StyledLabel>{locales.filterSortBy}</StyledLabel>
    </DesktopFilter>

    <MobileFilter active={active ? `true` : undefined} onClick={() => setActive(locales.filterSortBy)}>
      <StyledLabel>{locales.filterSortBy}</StyledLabel>
      <ArrowRightIcon active={active === locales.filterSortBy} />
    </MobileFilter>
  </>
))
