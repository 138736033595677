import React, { useMemo } from "react"

import { Container } from "../../Styled/Container"
import { Checkbox } from "../../Styled/Checkbox/Checkbox"
import { Panel, Row, Values, Value, Controls, PreviousIcon, StyledDropdownLabel, StyledTextButton } from "./SearchFilterStyles"

export const SearchFilterPanel = ({ active, options, filter, locales, setActive, handleChange }) => {


  // The size option comes back with multiple sizes that have SKUs and random data. This cleans up the data so that it only shows the intended sizes 
  const filteredOptions = useMemo(() => {
    return filter?.name == "Size" ? options?.filter(size => size?.key.length === 1) : options
  }, [options])

  return (
    <Panel active={active}>
      <Container>
        <Controls>
          <StyledTextButton onClick={() => setActive(null)}>
            <PreviousIcon />
            <span>{locales.back}</span>
          </StyledTextButton>
        </Controls>

        <StyledDropdownLabel>
          {filter.name == "Price_new" ? "price" : filter.name}
        </StyledDropdownLabel>

        <Row>
          <Values>
            {filter?.displayView === "list" || filter?.displayView === "range" || filter?.displayView === "checkbox" ?
              filteredOptions.map(({ key, checked }: { key: string; checked: boolean }) => {
                return (
                  <Value key={key} >
                    <Checkbox
                      key={key}
                      capitalize
                      centered
                      checked={checked}
                      externalState
                      large
                      name={key}
                      onChange={() => handleChange(key)}
                      isFilter={true}
                    >
                      {key?.replace(/-/g, ` `)}
                    </Checkbox>
                  </Value>
                );
              }
              ) : null}

          </Values>
        </Row>
      </Container>
    </Panel >
  )
}
