import React, { useEffect, useReducer, useState } from "react"

import { withSearchFilter } from "./withSearchFilter"
import { SearchFilterDropdown } from "./SearchFilterDropdown"
import { SearchFilterGrid } from "./SearchFilterGrid"
import { SearchFilterPalette } from "./SearchFilterPalette"
import { SearchFilterList } from "./SearchFilterList"
import { useFilters, Filter } from "@usereactify/search"
import { useSearch } from "@hooks/useSearch"

import { P } from "../../Styled/Text"
import {
  Wrapper,
  FilterPanel,
  StyledContainer,
  FiltersWrapper,
  Items,
  MobileHeader,
  MobileHeaderColumn,
  MobileFooter,
  CloseIcon,
  CloseButton,
  ResetButton,
  Overlay,
  ItemsFilter,
} from "./SearchFilterStyles"
import { PrimaryButton } from "../../Styled/Button"
import { SearchSort } from "../Sort/SearchSort"
import { SearchSortToggle } from "../Sort/SearchSortToggle"
import { ClearAll } from "@usereactify/search"

export const SearchFilter = withSearchFilter(({ active, filtersActive, locales, handleCloseFilter, setActive, setMeasuring, setValues, values }) => {
  const { filters } = useFilters()
  const { sortFilterList } = useSearch()
  const filterSwatch = filters?.find(({ displayView }) => displayView === "swatch")
  const filterShip = filters?.find(({ name }) => name === "Availability")

  return (
    <Wrapper active={filtersActive}>
      <FilterPanel active={filtersActive}>
        <StyledContainer>
          <MobileHeader>
            <MobileHeaderColumn>
              <P>{locales.filterLabel}</P>
            </MobileHeaderColumn>

            <MobileHeaderColumn align={`right`}>
              <CloseButton onClick={handleCloseFilter}>
                <CloseIcon large />
              </CloseButton>
            </MobileHeaderColumn>
          </MobileHeader>

          <FiltersWrapper>
            {filterSwatch && (
              <Filter
                key={filterSwatch.id}
                filter={filterSwatch}
                renderFilterList={({ filterListProps }) => {
                  return (
                    <Items align={`start`}>
                      <SearchFilterPalette locales={locales} filterListProps={filterListProps} />
                    </Items>
                  )
                }}
              />
            )}
            <Items align={`stretch`}>
              <>
                <SearchSortToggle active={active} setActive={setActive} />
                {sortFilterList(filters)?.map(filter => {
                  return (
                    <Filter
                      key={filter.id}
                      filter={filter}
                      renderFilterRange={({ filter, filterRangeProps }) => {
                        const amount = filterRangeProps?.options.some(({ checked }) => checked) ? 1 : 0
                        return (
                          <SearchFilterList
                            key={filter.name?.toString()}
                            active={active}
                            // value={values[filter.nanme] || []}
                            filter={filter}
                            setActive={setActive}
                            totalSelected={amount}
                          />
                        )
                      }}
                      renderFilterList={({ filterListProps }) => {
                        return (
                          <SearchFilterList
                            key={filter.name?.toString()}
                            active={active}
                            // value={values[filter.nanme] || []}
                            filter={filter}
                            setActive={setActive}
                            totalSelected={filterListProps.totalSelected}
                          />
                        )
                      }}
                    />
                  )
                })}
              </>
            </Items>
            {filterShip && (
              <Filter
                key={filterShip.id}
                filter={filterShip}
                renderFilterList={props => (
                  <ItemsFilter align={`end`}>
                    <SearchFilterGrid filterListProps={props.filterListProps} />
                  </ItemsFilter>
                )}
              />
            )}
          </FiltersWrapper>

          <MobileFooter>
            <ClearAll
              render={({ clearAll }) => (
                <ResetButton
                  hidden={false}
                  onClick={() => {
                    return clearAll()
                  }}
                >
                  {locales.filterReset} <CloseIcon large />
                </ResetButton>
              )}
            />
            <PrimaryButton full={`true`} onClick={handleCloseFilter}>
              {!filtersActive ? `${locales.show} totalResults+ ${locales.filterItems}` : locales.close}
            </PrimaryButton>
          </MobileFooter>
        </StyledContainer>
      </FilterPanel>
      <Overlay active={active} />
      <SearchSort active={active} setActive={setActive} />
      {filters?.map(filter => {
        return (
          <Filter
            key={filter.id}
            filter={filter}
            renderFilterRange={({ filter, filterRangeProps }) => {
              return (
                <SearchFilterDropdown
                  key={filter.name?.toString()}
                  locales={locales}
                  setMeasuring={setMeasuring}
                  active={filter.name === active}
                  setValues={setValues}
                  setActive={setActive}
                  filterListProps={filterRangeProps}
                />
              )
            }}
            renderFilterList={({ filter, filterListProps }) => (
              <SearchFilterDropdown
                key={filter.name?.toString()}
                locales={locales}
                setMeasuring={setMeasuring}
                active={filter.name === active}
                setValues={setValues}
                setActive={setActive}
                filterListProps={filterListProps}
              />
            )}
          />
        )
      })}
    </Wrapper>
  )
})
