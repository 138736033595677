import React from "react"

import { CloseIcon, Dropdown, Content, Row, Value, Controls, Values, Info, StyledLink, StyledTextButton } from "./SearchFilterStyles"
import { SearchFilterPanel } from "./SearchFilterPanel"
import { Container } from "../../Styled/Container"
import { Checkbox } from "../../Styled/Checkbox/Checkbox"
// import { Small } from "../../Styled/Text"
import { SearchFilterCount } from "./SearchFilterCount"
import { ClearAll } from "@usereactify/search"
import { useSearch } from "@app/hooks/useSearch"

export const SearchFilterItem = ({ active, locales, setActive, setMeasuring, filterListProps }) => {
  const { filter, options, handleChange } = filterListProps
  const { mutateFiltersList } = useSearch()

  return (
    <>
      <Dropdown active={active} onMouseEnter={() => setActive(filter?.name)} onMouseLeave={() => setActive(null)}>
        <Content>
          <Container width={`lg`}>
            <Row>
              <Values>
                {(filter?.displayView === "list" || filter?.displayView === "range") &&
                  mutateFiltersList(options, filter.name)?.map(({ key, checked, name }) => (
                    <Value key={key}>
                      <Checkbox
                        key={key}
                        capitalize
                        centered
                        checked={checked}
                        externalState
                        large
                        name={key}
                        onChange={() => handleChange(key)}
                        isFilter={true}
                      >
                        {name ? name.replace(/-/g, ` `) : key?.replace(/-/g, ` `)}
                      </Checkbox>
                    </Value>
                  ))}
              </Values>
            </Row>

            <Row>
              <Info>
                <SearchFilterCount />

                {/* {measuring && filter.name === "Size" && (
                  <Small>
                    <StyledLink as={`button`} onClick={() => setMeasuring(true)}>
                      {locales.filterMeasuring}
                    </StyledLink>
                  </Small>
                )} */}
              </Info>
              <ClearAll
                render={({ clearAll }) => (
                  <Controls>
                    <StyledTextButton onClick={() => clearAll()}>
                      {locales.filterReset} <CloseIcon />
                    </StyledTextButton>
                  </Controls>
                )}
              />
            </Row>
          </Container>
        </Content>
      </Dropdown>

      <SearchFilterPanel active={active} setActive={setActive} locales={locales} filter={filter} options={options} handleChange={handleChange} />
    </>
  )
}
